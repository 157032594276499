
export const  getEnvironmentConstants = () => {
    const environment = process.env.REACT_APP_ENVIRONMENT || 'local';
    const domain = process.env.REACT_APP_DOMAIN;
    const constants = {
      local: {
        REACT_APP_DEBUGGING: 1,
        REACT_APP_CREATE_NEW_USER: `${domain}/api/user/`,
        REACT_APP_LOG_TO_SERVER: `${domain}/setup/log/`,
      },
      production: {
        REACT_APP_DEBUGGING: 0,
        REACT_APP_CREATE_NEW_USER: `${domain}/api/user/`,
        REACT_APP_LOG_TO_SERVER: `${domain}/setup/log/`,
      },
    };
  
    return constants[environment];
  }
  