import React from "react";
import NavBar from "../components/NavBar";
import design from "../../../assets/images/new-icons/design.svg";
import together from "../../../assets/images/new-icons/together.svg";
import honest from "../../../assets/images/new-icons/honest.svg";
import real from "../../../assets/images/new-icons/real.svg";

import job from "../../../assets/images/new-icons/job.svg";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import { Link as RouterLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import LinkedIn from "@mui/icons-material/LinkedIn";

import thorsten from "../../../assets/images/profile/thorsten.jpg";
import florian from "../../../assets/images/profile/florian.jpg";
import vinita from "../../../assets/images/profile/vinita.jpg";
import claire from "../../../assets/images/profile//CLAIRE.png";
import leander from "../../../assets/images/profile/leander.jpg";
import marcel from "../../../assets/images/profile/marcel.jpg";
import abdul from "../../../assets/images/profile/abdul.jpg";
// import about from "../../../assets/images/profile/about.jpg";
import fhtr from "../../../assets/images/profile/fhtr.jpg";
import about1 from "../../../assets/images/profile/about1.png";
import elena from "../../../assets/images/profile/ELENA.png";
import nele from "../../../assets/images/profile/nele.jpeg";
import kaushik from "../../../assets/images/profile/kaushik.jpeg";

import job2 from "../../../assets/images/new-icons/job2.svg";
import ft from "../../../assets/images/new-icons/ft.svg";
import hybrid from "../../../assets/images/new-icons/hybrid.svg";
import arrow from "../../../assets/images/new-icons/arrow.svg";

import Footer from "../components/Footer";
import {
  IconArrowRight,
  IconMail,
  IconMessage2,
  IconPhone,
} from "@tabler/icons";
import { FormattedMessage } from "react-intl";
import { Chip } from "@mui/material";
import Partners from "../components/Partners";

import learning from "../../../assets/images/new-icons/learning.svg";
import blog from "../../../assets/images/new-icons/blog.svg";
import ThorstenCard from "ui-component/ThorstenCard";

const About = () => {
  return (
    <div className="aboout_mission">
      <NavBar />
      <div className="mission_container">
        <div
          className="mission_container__image"
          style={{ backgroundImage: `url(${design})` }}
        >
          <div className="mission_box">
            <div className="mission_container_heading_about">
              {" "}
              <FormattedMessage id="about" defaultMessage="About" />
            </div>
            <div className="mission_container_heading">Mission</div>
            <div className="mission_container_data">
              <FormattedMessage
                id="mission"
                defaultMessage={`
      Freeze Carbon is your sparring partner in the battle to find the best way possible to balance unavoidable emissions. 
      Connecting buyers with sellers whilst creating transparency and trust is our ambition. 
      Together, we can achieve a net-zero society.
    `}
              />
            </div>
          </div>
        </div>
      </div>

      {/* approach  */}
      <div className="our_approach_desktop">
        <div
          className="services_banner_container about_container"
          id="personalized-strategy"
        >
          <div className="service_banner_data about_banner_data">
            <div
              className="service_banner_heading"
              style={{ marginBottom: "0.5em" }}
            >
              <div className="mission_projects_priciples_box_image about_mission_projects_priciples_box_image">
                <img src={real} className="image_width" />
              </div>
              <FormattedMessage
                id="our_approach_heading"
                defaultMessage="Our approach"
              />
            </div>
            <div className="bullet-point-item">
              <span className="bullet-text our-approach ">
                <FormattedMessage
                  id="about_desc1"
                  defaultMessage="Freeze Carbon was called to live with the mission to create transparency into what a “good” carbon project actually looks like.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc2"
                  defaultMessage="We developed the Extra Mile Quality Analysis (“EMQA”) to achieve comparability and transparency in the carbon market. Our multistep process is built like an onion. Every time the assessment of one layer was successful, we move on to the next until we reach the core.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc3"
                  defaultMessage="We pre-qualify these projects, so our customers only have to deal with positive projects and can then objectively assess each detail before making a purchase decision.
                "
                />
                <FormattedMessage
                  id="about_desc4"
                  defaultMessage="EMQA was developed by combining conservative measures of the combination of the leading certification institutions and industry experts.
                "
                />
              </span>
            </div>
          </div>
          <div
            className="service_banner_image"
            style={{ justifyContent: "flex-end" }}
          >
            <img src={about1} style={{ width: "85%" }} />
          </div>
        </div>

        <div
          className="services_banner_container about_container"
          id="personalized-strategy"
        >
          <div className="service_banner_image">
            <img src={fhtr} style={{ width: "85%" }} />
          </div>
          <div className="service_banner_data about_banner_data">
            <div className="bullet-point-item">
              <span className="bullet-text our-approach ">
                <FormattedMessage
                  id="about_desc5"
                  defaultMessage="We have curated a list of projects which successfully passed this process. This offering is diverse in project types and regions.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc6"
                  defaultMessage="We are not bound to a specific project type, certification institute or country. We are bound by the commitment towards our clients to find great projects for them.

                "
                />

                <FormattedMessage
                  id="about_desc7"
                  defaultMessage="You will therefore find carefully selected projects which you can quickly read up on and get comfortable with before buying carbon credits.

                "
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="our-approach_mobile">
        <div
          className="services_banner_container about_container "
          id="personalized-strategy"
        >
          <div className="service_banner_data about_banner_data">
            <div className="bullet-point-item">
              <span className="bullet-text our-approach ">
                <FormattedMessage
                  id="about_desc5"
                  defaultMessage="We have curated a list of projects which successfully passed this process. This offering is diverse in project types and regions.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc6"
                  defaultMessage="We are not bound to a specific project type, certification institute or country. We are bound by the commitment towards our clients to find great projects for them.

                "
                />
                <FormattedMessage
                  id="about_desc7"
                  defaultMessage="You will therefore find carefully selected projects which you can quickly read up on and get comfortable with before buying carbon credits.

                "
                />
              </span>
            </div>
          </div>

          <div className="service_banner_image">
            <img src={fhtr} className="image_width" />
          </div>
        </div>
        <div
          className="services_banner_container about_container"
          id="personalized-strategy"
        >
          <div className="service_banner_data about_banner_data">
            <div
              className="service_banner_heading"
              style={{ marginBottom: "0.5em" }}
            >
              <div className="mission_projects_priciples_box_image about_mission_projects_priciples_box_image">
                <img src={real} className="image_width" />
              </div>
              <FormattedMessage
                id="our_approach_heading"
                defaultMessage="Our approach"
              />
            </div>

            <div className="service_banner_image">
              <img src={about1} className="image_width" />
            </div>
            <div className="bullet-point-item">
              <span className="bullet-text our-approach ">
                <FormattedMessage
                  id="about_desc1"
                  defaultMessage="Freeze Carbon was called to live with the mission to create transparency into what a “good” carbon project actually looks like.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc2"
                  defaultMessage="We developed the Extra Mile Quality Analysis (“EMQA”) to achieve comparability and transparency in the carbon market. Our multistep process is built like an onion. Every time the assessment of one layer was successful, we move on to the next until we reach the core.
                "
                />
                <br />
                <FormattedMessage
                  id="about_desc3"
                  defaultMessage="We pre-qualify these projects, so our customers only have to deal with positive projects and can then objectively assess each detail before making a purchase decision.
                "
                />
                <FormattedMessage
                  id="about_desc4"
                  defaultMessage="EMQA was developed by combining conservative measures of the combination of the leading certification institutions and industry experts.
                "
                />
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* principles  */}
      <div className="mission_projects" style={{ marginBottom: "0" }}>
        <div className="homepage_project_heading">
          <div className="homepage_project_heading_content">
            {" "}
            <FormattedMessage id="principle" defaultMessage="Principles" />
          </div>
        </div>
        <div className="mission_projects_priciples about_section_1">
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <img src={together} />
            </div>
            <div className="mission_projects_priciples_box_heading">
              <FormattedMessage id="together" defaultMessage="Together" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="together_desc"
                defaultMessage="Only as a collective of market participants, companies and teammates can we make the impossible possible."
              />
            </div>
          </div>
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <img src={honest} />
            </div>
            <div className="mission_projects_priciples_box_heading">
              <FormattedMessage id="simple" defaultMessage="Simple" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="simple_desc"
                defaultMessage="We break down the very complex topic of carbon credits into easily digestible pieces."
              />{" "}
            </div>
          </div>
          <div className="mission_projects_priciples_box">
            <div className="mission_projects_priciples_box_image">
              <img src={real} />
            </div>
            <div className="mission_projects_priciples_box_heading">
              {" "}
              <FormattedMessage id="transparent" defaultMessage="transparent" />
            </div>
            <div className="mission_projects_priciples_box_data">
              <FormattedMessage
                id="transparent_desc"
                defaultMessage="We take a very close look at carbon projects so that we understand all the details and can communicate them openly to our customers."
              />
            </div>
          </div>
        </div>
      </div>
      {/* team  */}

      <div id="/about#team">
        <div
          className="homepage_project_heading_data_content"
          style={{ marginBottom: "1em" }}
        >
          <div className="homepage_project_heading">
            <div className="homepage_project_heading_content">
              <FormattedMessage id="teamHeading" defaultMessage="Our Team" />
            </div>
          </div>
        </div>
        <div className="homepage_project_container_types our_team_row">
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={thorsten} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_name">
                  Thorsten Ratzlaff{" "}
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/thorsten-ratzlaff/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    {" "}
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">CEO & Co-Founder</div>
              </div>
            </div>
          </div>

          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={leander} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Commercial"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Leander Hellbusch
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/leander-hellbusch-a20223194/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Climate Consultant</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={elena} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Commercial"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Elena Gaggia
                  <RouterLink
                    to={{
                      pathname: "https://www.linkedin.com/in/elena-gaggia/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Climate Consultant</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={abdul} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Tech"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Abdul Azeem
                  <RouterLink
                    to={{
                      pathname: "https://www.linkedin.com/in/abdulazeem1/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Full-Stack Developer</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={vinita} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Tech"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Vinita Rane{" "}
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/vinita-rane-7595671aa/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Full-Stack Developer</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={florian} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Advisor"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Florian Haubner{" "}
                  <RouterLink
                    to={{
                      pathname: "https://www.linkedin.com/in/florian-haubner/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Strategy & Co-Founder</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={nele} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Advisor"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Nele Erdmann{" "}
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/nele-erdmann-9a023816/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Quality</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={kaushik} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Quality"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Koushikh Karunakar{" "}
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/koushikh-karunakar-468a46103/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Climate Scientist</div>
              </div>
            </div>
          </div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_container_types_row_column our_team_row_column">
              <div className="our_team_member_photo">
                <img src={claire} className="our_team_member_photo_image" />
              </div>
              <div className="member_details">
                <div className="member_social">
                  <Chip
                    label="Commercial"
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                </div>
                <div className="member_name">
                  Claire Pereira{" "}
                  <RouterLink
                    to={{
                      pathname:
                        "https://www.linkedin.com/in/claire-pereira-borgmeyer-8471b556/",
                    }}
                    target="_blank"
                    style={{ width: "25px", height: "25px" }}
                  >
                    <LinkedIn color="primary" fontSize="medium" />
                  </RouterLink>
                </div>
                <div className="member_position">Marketing</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* partners  */}

      <Partners />

      <div className="mission_projects" style={{ margin: "0" }} id="jobs">
        <div className="homepage_project_heading " style={{ margin: "0" }}>
          <div className="homepage_project_heading_content">Jobs</div>
        </div>

        <div
          className="homepage_project_heading_data_content"
          style={{ fontSize: "1.5em" }}
        >
          <div className="homepage_project_heading_data_content_value">
            Tech
          </div>
        </div>

        <div className="mission_projects_priciples">
          <div className="mission_projects_priciples_box mission_projects_priciples_box_job">
            <div className="mission_projects_priciples_box_job_image">
              <img src={job} />
            </div>
            <div className="mission_projects_priciples_box_job_heading">
              Full-stack developer
              <img src={arrow} style={{ marginLeft: "2em" }} />
            </div>
            <div className="mission_projects_priciples_box_job_labels">
              <img src={ft} />
              Full time
              <img src={hybrid} />
              Hybrid
            </div>
          </div>
          <div className="mission_projects_priciples_box mission_projects_priciples_box_job">
            <div className="mission_projects_priciples_box_job_image">
              <img src={job} />
            </div>
            <div className="mission_projects_priciples_box_job_heading">
              Backend developer{" "}
              <img src={arrow} style={{ marginLeft: "2em" }} />
            </div>
            <div className="mission_projects_priciples_box_job_labels">
              <img src={ft} />
              Full time
              <img src={hybrid} />
              Hybrid
            </div>
          </div>
        </div>

        <div
          className="homepage_project_heading_data_content"
          style={{ fontSize: "1.5em" }}
        >
          <div className="homepage_project_heading_data_content_value">
            Commercial
          </div>
        </div>
        <div className="mission_projects_priciples">
          <div className="mission_projects_priciples_box mission_projects_priciples_box_job">
            <div className="mission_projects_priciples_box_job_image">
              <img src={job2} />
            </div>
            <div className="mission_projects_priciples_box_job_heading">
              Sr. Account Executive
              <img src={arrow} style={{ marginLeft: "2em" }} />
            </div>
            <div className="mission_projects_priciples_box_job_labels">
              <img src={ft} />
              Full time
              <img src={hybrid} />
              Hybrid
            </div>
          </div>
          <div className="mission_projects_priciples_box mission_projects_priciples_box_job">
            <div className="mission_projects_priciples_box_job_image">
              <img src={job2} />
            </div>
            <div className="mission_projects_priciples_box_job_heading">
              Jr. Account Executive
              <img src={arrow} style={{ marginLeft: "2em" }} />
            </div>
            <div className="mission_projects_priciples_box_job_labels">
              <img src={ft} />
              Full time
              <img src={hybrid} />
              Hybrid
            </div>
          </div>
          <div className="mission_projects_priciples_box mission_projects_priciples_box_job">
            <div className="mission_projects_priciples_box_job_image">
              <img src={job2} />
            </div>
            <div className="mission_projects_priciples_box_job_heading">
              Sales Development...
              <img src={arrow} style={{ marginLeft: "2em" }} />
            </div>
            <div className="mission_projects_priciples_box_job_labels">
              <img src={ft} />
              Full time
              <img src={hybrid} />
              Hybrid
            </div>
          </div>
        </div>
      </div>

      {/* contact card  */}

      <ThorstenCard />

      <Footer />
    </div>
  );
};

export default About;
