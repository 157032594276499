import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Link,
} from "@mui/material";
import { auth } from "../firebase"; // Your Firebase config file
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { getEnvironmentConstants } from "utils/constants";
import { GenericBackend } from "GenericBackend";
import { MuiTelInput } from "mui-tel-input";

const SignUpFormDE: React.FC = () => {
  // Form State
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationType, setRegistrationType] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [isVerificationSent, setIsVerificationSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Handle form submission
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Fehler zurücksetzen, damit bei einer erneuten Einreichung keine vorherigen Fehler angezeigt werden
    setErrorMessage("");

    // Grundlegende Validierung
    if (password !== confirmPassword) {
      setErrorMessage("Die Passwörter stimmen nicht überein");
      return;
    }
    if (!acceptTerms) {
      setErrorMessage("Bitte stimmen Sie den Geschäftsbedingungen zu");
      return;
    }

    try {
      // Neuer Benutzer mit E-Mail und Passwort erstellen
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // E-Mail-Bestätigung senden
      if (user) {
        await sendEmailVerification(user);
        console.log("Bestätigungs-E-Mail gesendet");
        setIsVerificationSent(true);

        const token = await user.getIdToken(/* forceRefresh */ true);
        // Benutzer in der Datenbank erstellen
        const formData = {
          email,
          first_name: firstName,
          last_name: lastName,
          type: 0, // Typ: Normale Benutzer (Käufer und Verkäufer) haben den Typ 0
          company_name: companyName,
          service_fee: 0,
          is_buyer: registrationType === "buyer",
          user_uuid: user.uid,
          marketing_consent: marketingConsent,
          accept_terms: acceptTerms,
        };

        await GenericBackend.post(
          getEnvironmentConstants().REACT_APP_CREATE_NEW_USER,
          formData,
          token
        );
      }
    } catch (error: any) {
      console.log(error);
      setErrorMessage(
        "Etwas ist bei der Kontoerstellung schiefgegangen. Bitte kontaktieren Sie den Support: support@freezecarbon.com"
      );
    }
  };

  return (
    <Box sx={{ px: 2, py: 2, width: "100%" }}>
      {!isVerificationSent ? (
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {/* Vorname */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Vorname"
                variant="outlined"
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            {/* Nachname */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nachname"
                variant="outlined"
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            {/* E-Mail */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Berufliche E-Mail-Adresse"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            {/* Telefonnummer */}
            <Grid item xs={12} sm={6}>
              <MuiTelInput
                label="Telefonnummer"
                fullWidth
                defaultCountry="DE" // Default to Germany
                value={phoneNumber}
                onChange={(newValue) => setPhoneNumber(newValue)}
              />
            </Grid>
            {/* Firmenname */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Firmenname"
                variant="outlined"
                required
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            {/* Art der Registrierung */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="registration-type-label">
                  Art der Registrierung
                </InputLabel>
                <Select
                  labelId="registration-type-label"
                  id="registration-type"
                  value={registrationType}
                  onChange={(e) => setRegistrationType(e.target.value)}
                  label="Art der Registrierung"
                  required
                >
                  <MenuItem value="buyer">Kaufinteressent:in</MenuItem>
                  <MenuItem value="seller">
                    Klimaschutzprojekt Entwickler:in
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* Passwort */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Passwort"
                type="password"
                variant="outlined"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            {/* Passwort bestätigen */}
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Passwort bestätigen"
                type="password"
                variant="outlined"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            {/* Geschäftsbedingungen */}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    required
                  />
                }
                label={
                  <Typography variant="body2" sx={{ display: "inline-block" }}>
                    Mit dem Klicken auf „Zustimmen und Beitreten“ erklärt man
                    sich mit den{" "}
                    <Link
                      href="/pao3wdv5n-contract"
                      target="_blank"
                      rel="noopener"
                    >
                      Allgemeinen Geschäftsbedingungen
                    </Link>{" "}
                    und der{" "}
                    <Link
                      href="/data-privacy-en"
                      target="_blank"
                      rel="noopener"
                    >
                      Datenschutzrichtlinie
                    </Link>{" "}
                    von Freeze Carbon einverstanden.
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={marketingConsent}
                    onChange={(e) => setMarketingConsent(e.target.checked)}
                    name="marketingConsent"
                  />
                }
                label={
                  <Typography variant="body2">
                    Wenn man wünscht, Neuigkeiten und Marketing-E-Mails von
                    Freeze Carbon zu erhalten, kann man dies hier auswählen. Das
                    Abbestellen ist jederzeit möglich.
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {/* Zustimmen und Beitreten Button */}
          <Box sx={{ mt: 3 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{ padding: "10px 0", fontSize: "16px" }}
            >
              Zustimmen und Beitreten
            </Button>
          </Box>
          <div id="recaptcha-container"></div>
        </form>
      ) : (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">
            Vielen Dank für die Registrierung eines Kontos. Bitte gehen Sie zu
            Ihrem E-Mail-Posteingang (oder Spam) und bestätigen Sie Ihre
            E-Mail-Adresse.
          </Typography>
        </Box>
      )}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>}
    </Box>
  );
};

export default SignUpFormDE;
