import axios from "axios";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getEnvironmentConstants } from "utils/constants";

export class GenericBackend {
  //   static authenticateUser = async () => {
  //     const user = firebase.auth().currentUser;
  //     if (user) {
  //       const idToken = await user.getIdToken(/* forceRefresh */ true);
  //       const token = `Bearer ${idToken}`;
  //       return token;
  //     } else {
  //       throw new Error("User not authenticated.");
  //     }
  //   };

  static post = async (url, data, token, publicURL = false) => {
    // let token = "";
    // if (!publicURL) token = await GenericBackend.authenticateUser();

    try {
      const response = await axios.post(url + "", data, {
        withCredentials: true,
        headers: {
          authorization: token,
        },
      });
      return response;
    } catch (error: any) {
      GenericBackend.logger(
        "Website: post request error: " + error.message,
        "error",
        token
      );
      return error.response;
    }
  };

  static postv2 = async (url, data, token, publicURL = false) => {
    // let token = "";
    // if (!publicURL) token = await GenericBackend.authenticateUser();

    try {
      const response = await axios.post(url + "", data, {
        withCredentials: true,
        headers: {
          authorization: token,
        },
      });

      return response;
    } catch (error: any) {
      console.log(error);
      return error.response;
    }
  };

  static logger = async (
    message: string,
    severity = "error",
    token: string
  ) => {
    await axios.post(
      getEnvironmentConstants().REACT_APP_LOG_TO_SERVER,
      JSON.stringify({ message, severity }),
      {
        withCredentials: true,
        headers: {
          authorization: token,
        },
      }
    );
  };
}
